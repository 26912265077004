<template>
	<h1>Copyright Policy</h1>
	<p>
		It is Arthur’s policy to comply fully with the Digital Millennium Copyright Act and other applicable
		intellectual property laws (collectively the "Act"). This page lists our requirements under the Act for
		notice of copyright infringement and for responses to such a notice if you or your materials are accused.
	</p>
	<p>
		We have appointed and registered a copyright agent to receive notifications of claimed infringement and
		have informed the U.S. Copyright Office of this appointment. You can contact our copyright agent below:
	</p>

	<p>
		<FormButton :level="1" @click="$router.push({ name: 'LegalRemoval' })" value="Contact copyright agent" />
	</p>
	<p>
		As explained in more detail below, the Act requires the removal or disabling of access to content claimed
		to be, or reasonably determined by Arthur, the subject of infringing activity. If the Act requires content
		to be removed from Arthur, we will remove the remove the content.
	</p>

	<h3>Notice of Copyright Infringement</h3>
	<p>
		If you believe that content on Arthur, at the direction of a third party, violates any of your exclusive
		rights under United States copyright law, you must send a written communication to our Copyright Agent at
		the contact above. The written communication should include the following:
	</p>
	<ul class="bump-b">
		<li>
			Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works
			at a single online site are covered by a single notification, a representative list of such works at
			that site.
		</li>
		<li>
			Identification of the material that is claimed to be infringing or to be the subject of infringing
			activity and that is to be removed or access to which is to be disabled, and information reasonably
			sufficient to permit us to locate the material.
		</li>
		<li>
			Information reasonably sufficient to permit us to contact you, such as address, telephone number, and,
			if available, an e-mail address.
		</li>
		<li>
			A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
			right that is allegedly infringed.
		</li>
		<li>
			The following statement: "I have a good faith belief that the use of materials described above is not
			authorized by the copyright owner, its agent, or the law."
		</li>
		<li>
			The following statement: "The information in this notification is accurate and that I swear, under
			penalty of perjury, that I am the copyright owner or am authorized to act on behalf of the owner of an
			exclusive right that is allegedly infringed."
		</li>
	</ul>
	<p>
		We have no other role to play either in prosecuting or defending claims of infringement, and cannot be
		held accountable in any case for damages, regardless of whether a claim of infringement is found to be
		true or false. Pursuant to the Act, you may be liable for damages, including court costs and attorneys
		fees, if you misrepresent that a product or activity is infringing your copyrights. If you are not sure
		whether material on Arthur infringes your copyright, we urge you to first consult an attorney.
	</p>

	<h3>Counter-Notice</h3>
	<p>
		Upon receipt of the above notice of infringement, we will respond expeditiously and remove, or disable
		access to, the material that is claimed to be infringing or to be the subject of infringing activity as
		required by the Act. Our Copyright Agent will take reasonable steps to promptly notify the affected party
		who uploaded the material.
	</p>
	<p>
		If we have removed your content from Arthur due to suspicion of copyright infringement, you may dispute
		the alleged infringement by sending a written communication to our Copyright Agent above. That written
		communication should include the following:
	</p>
	<ul class="bump-b">
		<li>
			Identification of the content that has been removed from Arthur or to which access has been disabled.
		</li>
		<li>
			Information reasonably sufficient to permit us to contact you, such as address, telephone number, and,
			if available, an e-mail address.
		</li>
		<li>
			A physical or electronic signature from you or from a person authorized to act on your behalf.
		</li>
		<li>
			The following statement: "Under penalty of perjury, I have a good faith belief that the material was
			removed or disabled as a result of mistake or misidentification of the material to be removed or
			disabled."
		</li>
		<li>
			The following statement: "The information in this notification is accurate, and I swear, in good faith
			and under penalty of perjury, that the content was removed or disabled as a result of mistake or
			misidentification of the material to be removed or disabled."
		</li>
		<li>
			The following statement: "I consent to the jurisdiction of New York for the judicial district in which
			such address is located, and will accept service of process from the person who provided notification of
			copyright infringement or an agent of such person."
		</li>
	</ul>
	<p>
		If you have served a counter notification complying with the above requirements, please note that we will
		promptly provide the person who provided the initial infringement statement with a copy of the counter
		notification and will inform such person that Arthur will replace the removed material or cease disabling
		access to it within 10-14 business days. We will then replace the removed material and cease disabling
		access to within 10-14 business days following receipt of the counter notice unless the copyright owner
		delivers to our Copyright Agent notice that it has filed an action seeking a court order. If we receive a
		notification from a copyright holder who has procured an enforceable injunction prohibiting Arthur from
		providing access to allegedly infringing material, we will follow the requirements of the injunction.
	</p>

	<h3>Other Types of Infringement</h3>
	<p>
		The policies and processes outlined above are applicable to copyright only. If you discover any content
		that you believe to be in infringement of any other intellectual property rights or in violation of our
		policies, please contact:
	</p>
	<p>
		<FormButton @click="$router.push({ name: 'LegalRemoval' })" value="Contact copyright agent" />
	</p>

	<h3>Revision</h3>
	<p>
		We may revise this policy at any time, including by posting a new version at this website.
	</p>
</template>

<script>
import FormButton from '@/components/FormButton'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'LegalCopyright',
	components: {
		FormButton,
	},
	setup() {
		injectMetaData({
			title: 'Arthur.io • Copyright Policy',
		})
	},
}
</script>
